<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
      :hideCompanyChip="true"
    />
    <v-row>
      <v-col>
        <DataTable
          :headers="headers"
          :items="items"
          :preloader="fetchinData"
          :edit="true"
          :trash="false"
          :permissions="auth.permissions"
          @tableEvent="emittedTableEvent"
        ></DataTable>
      </v-col>
    </v-row>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fixed
          fab
          bottom
          right
          color="primary"
          @click="addItem"
          v-bind="attrs"
          v-on="on"
          :disabled="disabledByPermission(writePerm)"
          ><v-icon color="white">mdi-plus</v-icon></v-btn
        >
      </template>
      <span>Dokument anlegen</span>
    </v-tooltip>

    <AreYouSureDialog
      title="Dieses Dokument löschen?"
      text="Möchtest Du dieses Dokument tatsächlich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden."
      :dialog="safeguardingDialog"
      @dialogEmit="safeguarding"
    />
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader";
import DataTable from "@/components/ui/DataTable";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";
export default {
  name: "Companies",

  components: {
    PageHeader,
    DataTable,
    AreYouSureDialog,
  },

  data() {
    return {
      safeguardingDialog: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      pageTitle: this.$t("plugins.companies.name"),
      pageDescription: this.$t("plugins.companies.description"),
      fetchinData: false, // should be true when fetching data
      auth: [],
      items: [],
      writePerm: null,
      readPerm: null,
      deletePerm: null,
      masterPerm: null,
      itemToDelete: null,
      headers: [
        { text: "id", value: "id" },
        { text: this.$t("phrases.Name"), value: "name" },
        {
          text: this.$t("phrases.legalFormName"),
          value: "legalFormName",
        },
        {
          text: this.$t("phrases.businessCategoryName"),
          value: "businessCategoryName",
        },
        { text: this.$t("phrases.CreatedDate"), value: "created" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },

  props: [],

  created: function () {
    this.getData();
  },

  methods: {
    async deleteItem() {
      const url = `/company/${this.itemToDelete.uuid}}`;
      let response = await this.delete(url); // await data from mixin

      if (response.status === 200) {
        let indexToDelete = null;

        this.items.forEach((el, index) => {
          if (el.uuid === this.itemToDelete.uuid) {
            indexToDelete = index;
          }
        });

        this.items.splice(indexToDelete, 1);

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Der Datensatz wurde gelöscht";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    safeguarding(value) {
      this.safeguardingDialog = false;

      if (value) {
        this.deleteItem();
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.get(`manager/companies`); // await data from mixin

      this.items = response.data.data;
      this.auth = response.data.auth;
      this.writePerm = this.auth.permissions.write;
      this.deletePerm = this.auth.permissions.delete;
      this.readPerm = this.auth.permissions.read;
      this.masterPerm = this.auth.permissions.master;
      this.fetchinData = false;
    },

    emittedTableEvent(obj) {
      if (obj.action === "editItem") {
        this.$router.push({
          name: "manager.companies.:id",
          params: { id: obj.itemUuid },
        });
      } else if (obj.action === "deleteItem") {
        this.itemToDelete = obj.item;
        this.safeguardingDialog = true;
      }
    },

    async addItem() {
      let response = await this.post(`manager/companies`); // await data from mixin

      let newItem = response.data.data.result[0];
      this.items.push(newItem);

      /* start snackbar */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Das Dokument wurde erfolgreich angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "green";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },
  },

  computed: {
    //
  },

  mixins: [api, helpers],
};
</script>
